export default [
  {
    value: 1,
    text: "Ödəniş gözləyən",
  },
  {
    value: 2,
    text: "Buraxılıb",
  },
  {
    value: 3,
    text: "Dayandırılıb",
  },
  {
    value: 4,
    text: "İmtina edilib",
  },
  {
    value: 5,
    text: "NV dəyişdirilib",
  },
  {
    value: 6,
    text: "Müddəti bitib",
  },
  {
    value: 7,
    text: "Draft",
  },
]