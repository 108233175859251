<template>
  <div v-if="isLoading">
    <ContractSkeleton />
  </div>
  <div v-else class="contract">
    <div class="row mb-4">
      <div class="d-flex align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <img src="@/assets/images/arrow-left.svg" class="cursor-pointer" @click="goBack" />
        <span class="h5 contract-head ms-2 mb-0">
          Müqavilə məlumatları
        </span>
      </div>
      <div class="d-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 justify-content-end">
        <div class="me-3">
          <button :disabled="isLoader" class="btn-print" @click="downloadDocument">
            <span class="spinner-border spinner-border-sm" v-if="isLoader" />
            <img src="@/assets/images/archive-done.svg" />
            Çap et
          </button>
        </div>
        <div v-if="contract?.status == 6">
          <button @click="next" class="btn-contract">
            Yenilə
          </button>
        </div>
        <div v-if="contract?.status == 1"  class="d-inline-block" @click="goPayment" >
          <button class="btn-contract">
            Ödə
          </button>
        </div>
      </div>
    </div>
    <div class="contract-items">
      <p class="
       contract-item-head
      ">
        İcbari sığorta müqavilə barədə məlumat
      </p>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">Müqavilənin nömrəsi</div>
            <div class="contract-item-subtitle">
              {{ contract?.contractNumber }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Sığorta şirkəti:
            </div>
            <div class="contract-item-subtitle">{{ contract?.insuranceCompanyName }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              İcbari sığortanın növü
            </div>
            <div class="contract-item-subtitle">AVSMMIS</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Ödəniş kodu:
            </div>
            <div class="contract-item-subtitle">{{ contract?.paymentNumber }}</div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Qüvvəyə minmə tarixi:
            </div>
            <div class="contract-item-subtitle">{{ contract?.effectiveDate }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Etibarlıdır:
            </div>
            <div class="contract-item-subtitle">{{ contract?.expiryDate }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Sığorta məbləği:
            </div>
            <div class="contract-item-subtitle">5000.00 AZN</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Sığorta haqqı:
            </div>
            <div class="contract-item-subtitle">{{ contract?.price }} AZN</div>
          </div>
        </div>
      </div>

    </div>

    <div class="contract-items mt-5">
      <p class="
       contract-item-head
      ">
        NV barədə məlumat
      </p>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 contract-item">
            <div class="contract-item-title">NV qeydiyyat nişanı</div>
            <div class="contract-item-subtitle">
              {{ contract?.carNumber }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-4 contract-item">
            <div class="contract-item-title">
              Ban
            </div>
            <div class="contract-item-subtitle">{{ contract?.bodyNumber }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 contract-item">
            <div class="contract-item-title">
              Marka
            </div>
            <div class="contract-item-subtitle">{{ contract?.make }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 contract-item">
            <div class="contract-item-title">
              Model
            </div>
            <div class="contract-item-subtitle">{{ contract?.model }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 contract-item">
            <div class="contract-item-title">
              İstehsal ili
            </div>
            <div class="contract-item-subtitle">{{ contract?.yearOfManufacture }}</div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Mühərrik nömrəsi
            </div>
            <div class="contract-item-subtitle">{{ contract?.engineNumber }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Mühərrik həcmi
            </div>
            <div class="contract-item-subtitle">{{ contract?.engineCapacity }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Max. kütlə
            </div>
            <div class="contract-item-subtitle">{{ contract?.maxPermissibleMass }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Oturacaqların sayı
            </div>
            <div class="contract-item-subtitle">{{ contract?.passengerSeatCount }}</div>
          </div>
        </div>
      </div>

    </div>


    <div class="contract-items mt-5">
      <p class="
       contract-item-head
      ">
        Şəxs barədə məlumat
      </p>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">A.S.A</div>
            <div class="contract-item-subtitle">
              {{ contract?.fullName }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Ş/V FİN kod
            </div>
            <div class="contract-item-subtitle">{{ contract?.pin }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Ş/V Seriya və nömrəsi
            </div>
            <div class="contract-item-subtitle">{{ contract?.documentNumber }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Mobil nömrə
            </div>
            <div class="contract-item-subtitle"> {{ contract?.phone }}</div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">Ünvan</div>
            <div class="contract-item-subtitle">
              {{ contract?.address }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">Sürücülük vəsiqəsi</div>
            <div class="contract-item-subtitle">
              {{ contract?.certificateNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end  pt-2 mt-3">
      <a href="#" class="d-flex align-items-center  contract-footer">
        <img src="@/assets/images/info-contract.svg" />
        <div @click="downloadPDF" class="h6  mb-0 ms-2">Müqavilə şərtlərinə bax</div>
      </a>
    </div>
  </div>
  <div id="contract-document" class=" p-4 mb-4">
    <img v-if="contractImage" :src="`data:image/png;base64, ${contractImage}`" style="width: 100%" />
  </div>
</template>

<script>
import api from "@/api/cmtpl.api";
import cmtplStatues from "@/data/CmtplContractStatuses";
import fileExt from "@/services/file.ext";
import ContractSkeleton from "./ContractSkeleton.vue";

export default {
  components: { ContractSkeleton },
  data: () => ({
    isLoading: false,
    isLoader:false,
    contract: null,
    statuses: cmtplStatues,
    contractImage: null,
  }),
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const { contractNumber } = this.$route.params;

      this.isLoading = true;

      api
        .getContractByNumber(contractNumber)
        .then((data) => (this.contract = data))
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },


    downloadDocument() {
        const { contractNumber } = this.$route.params;
        this.isLoader = true;

        api.getContractPdf(contractNumber)
            .then((response) => {
                const blob = fileExt.dataURItoBlob(response.documentPdf);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'document.pdf'; 
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoader = false));
    },

    downloadPDF() {
      const pdfUrl = '/cmtpl.pdf'; 
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'cmtpl.pdf'; 
      link.click();
    },

    getStatusDesc(status) {
      return cmtplStatues.find((o) => o.value === status)?.text ?? "...";
    },
    goBack() {
      this.$router.go(-1);
    },

    goPayment(){
      this.$router.push({
        name: "profile-cmtpl-contracts-payment",
        query: { contractNumber:this.contract.contractNumber, companyName:this.contract.insuranceCompanyName, 
          price:this.contract.price,paymentNumber:this.contract.paymentNumber},
      });
    },

    next(){
      let phone=this.contract?.phone.slice(3);
      let carNumber=this.contract?.certificateNumber.slice(2)
      let carSeria=this.contract?.certificateNumber.slice(0, 2)
      let number=this.contract?.documentNumber.slice(2)
      let seria=this.contract?.documentNumber.slice(0, 2)
      this.$router.push({
        name: "profile-car-insurance",
        params: {carNumber:this.contract.carNumber, pin:this.contract.pin,
        certificateNumber:this.contract.certificateNumber,phone:phone,
        number:number,seria:seria,
        carNumber:carNumber,carSeria:carSeria
        },
      });
    }
  },
};
</script>